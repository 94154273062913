
  .inputCalendar {
    color: white;
    background-color: #336EF4;
    font-weight: 500;
    height: 40px;
    font-size: 14px;
    transition: all 0.3s ease;
    border-radius: 5px;
    width: 100%; 
    font-family: Muli;
    margin-bottom: 43px !important;
    text-align: center;
  }
  .inputCalendar > div {
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .inputCalendar::placeholder {
    color: #009688;
  }
  
  .inputCalendar:focus {
    border-color: #009688;
    opacity: 1;
  }

  .react-daterange-picker__inputGroup__input{
    color: white;
  }
  
  .inputCalendar--empty {
    color: #959595;
    opacity: 0.6;
  }
  
  .inputCalendar--error {
    border-color: #e73e39;
    opacity: 0.8;
  }
  
  .calendar {
    font-family: Arial, "Open Sans", "Helvetica Neue", sans-serif;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    min-width: 250px;
    width: 280px;
    /* padding: 16px; */
    transition: all 0.2s ease;
    border: none;
    overflow: hidden;
    
  }
  
  @media screen and (max-width: 600px) {

    .react-daterange-picker__calendar {
      width: 100%;
      max-width: 100vw;
      z-index: 1;
  }
    .react-calendar {
      width: 100%;
      max-width: 100%;
      background: white;
      border: 1px solid #a0a096;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 1.125em;
  } 
    .calendar {
      font-family: Arial, "Open Sans", "Helvetica Neue", sans-serif;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      width: 100%;
      /* padding: 16px; */
      transition: all 0.2s ease;
      border: none;
      overflow: hidden;
      
    }
    .inputCalendar {
      color: white;
      background-color: #336EF4;
      font-weight: 500;
      height: 40px;
      font-size: 14px;
      transition: all 0.3s ease;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 43px !important;
      text-align: center;
      font-family: Muli;
      margin-top: 20px !important;
    }

    .react-daterange-picker__inputGroup__input {
      color: white;
      opacity: inherit;
      text-transform: capitalize;
    }
  }
  
  .calendar .react-calendar__tile--now {
    background: transparent;
  }
  .calendar .react-calendar__tile--now:before {
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    border-bottom: 7px solid #009688;
    border-radius: 4px;
  }
  
  .calendar .react-calendar__tile--rangeStart {
    background: #90a4ae;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  .calendar .react-calendar__tile--range {
    background: #90a4ae;
  }
  .calendar .react-calendar__tile--rangeEnd {
    background: #90a4ae;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  .calendar .react-calendar__tile:focus {
    background: #64ffda;
    color: #000;
  }
  /* class="react-calendar__tile react-calendar__tile--active react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__month-view__days__day" */
  
  .calendar .rdrDefinedRangesWrapper {
    display: none;
  }
  .calendar .rdrCalendarWrapper {
    width: 280px;
  }
  .calendar .rdrMonth,
  .calendar .rdrWeekDays {
    width: calc(280px - 20px);
    box-sizing: border-box;
  }
  .calendar .rdrDayDisabled {
    background: #fff;
  }
  .calendar .rdrDayDisabled span {
    color: #eee;
  }

  .react-daterange-picker, .react-daterange-picker *, .react-daterange-picker *:before, .react-daterange-picker *:after {
   
    text-transform: capitalize;
}
  